<template>
	<div>
		<div class="avatar" :style="avatarSize">
			<!-- <span class="bg-dark text-white avatar-letter" v-if="image">
				<img class="border border-0 border-primary avatar-image" :src="image" v-if="image"/> 
			</span> -->
			<span class="bg-dark text-white avatar-letter" >
				{{ userInitials }}
			</span> 
		</div>
		<div class="avatar-name h4 ms-2">
			<span>{{ author.name }}</span><br/>
			<span class="avatar-caption">Soca Islands member since {{ formatDate(author.created_at, "MMMM dd, yyyy") }}</span>
		</div>
	</div>
</template>
<script>
import { formatDate } from "../../utils";
export default {
	name: 'AvatarPosts',
	props: {
		width: {
			type: Number,
			default: 48
		},
		height: {
			type: Number,
			default: 48
		},
		author:{
			type: Object,
			required: true
		}
  },
	computed: {
		avatarSize(){
			return {width: `${this.width}px`, height: `${this.height}px`}
		},
		userInitials(){
			const {author} = this;
			return `${author.name?.slice(0,1)}`;
		},
	},
	data(){
		return {
			formatDate,
		}
	},
	mounted(){
		
	}
}
</script>
<style scoped>
.avatar{
    vertical-align: middle;
    position: relative;
	background-color: #000;
    color: #fff;
    border-radius: 50%;
	float: left;
}
.avatar-letter{
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: .9rem;
}
.avatar-name{
	float: left;
}
.avatar-caption{
	font-size: .8rem;
	float: left;
	font-weight: 400;
}
</style>
