<template>
	<div class="col-12">
		<ul class="list-group my-3" v-if="isLoggedIn">
			<li class="list-group-item">
				<textarea class="form-control my-3" rows="5" v-model="message" placeholder="Why are you reporting this item?"></textarea>
				<div class="form-check my-3">
					<input class="form-check-input" type="checkbox" :value="true" v-model="dataConsent" id="checkDataConsent">
					<label class="form-check-label" for="checkDataConsent">
						I give consent to Soca Islands to process my data.
					</label>
				</div>
				<div class="mb-3" align="right">
					<button class="btn btn-outline-dark btn-sm me-3" @click="$emit('hide-report-form')">
						<span>Cancel</span>
					</button>
					<button class="btn btn-dark btn-sm" @click="btnSubmitReportClick" :disabled="!isFormValid">
						<span v-if="!isBusy">Submit report</span>
						<div class="spinner-border text-light" role="status" v-else>
							<span class="sr-only">Loading...</span>
						</div>
					</button>
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
import { useToast } from 'vue-toastification';
import { 
	collection, 
	addDoc,
} from "firebase/firestore"; 
import { 
	collections, 
	db 
} from "../../firebaseConfig";

const toast = useToast();

export default {
	name: "ReportPost",
	props:{
		author: {
			type: Object,
			required: true,
		}
	},
	computed: {
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		userProfile(){
			return this.$store.state.userProfile
		},
		isFormValid(){
			if(this.message.length < 35){
				return false;
			}
			if(!this.dataConsent){
				return false;
			}
			return true;
		},
	},
	methods:{
		async btnSubmitReportClick(){
			this.isBusy = true;
			const timestamp = Date.now()
			const report = {
				created_at: timestamp,
				updated_at: timestamp,
				details: this.message,
				data_consent: this.dataConsent,
				author: {
					id: this.userProfile.id,
					name: `${this.userProfile.fname} ${this.userProfile.lname}`,
					created_at: this.userProfile.created_at,
				},
				reported_id: this.author.id
			};
			try{
				await addDoc(collection(db, collections.REPORTS), report);
				toast.success("Report submitted!");
				this.message = "";
				this.dataConsent = false;
				this.$emit('hide-report-form');
			}catch(error){
				console.log(error);
				toast.error("Report not submitted!")
			}finally{
				this.isBusy = false;
			}
		} 
	},
	data(){
		return {
			isBusy: false,
			message: "",
			dataConsent: false,
		}
	},
	mounted(){
		
	}
}
</script>
