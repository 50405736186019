<template>
	<div class="row">
		<div class="col-12 alert alert-secondary" v-if="isLoggedIn">
			<label class="small text-dark bold">Contact {{ author.name }}</label>
			<textarea rows="4" class="form-control my-3" v-model="message" />
			<div class="d-grid" v-if="userProfile.id && !flagged" align="right">
				<button class="btn btn-dark btn-lg" 
				@click="btnSendMessageClick"
				:disabled="!isFormValid"
				>
					<span v-if="!isBusy">Send message</span>
					<div class="spinner-border text-light" role="status" v-else>
						<span class="sr-only">Loading...</span>
					</div>
				</button>
			</div>
		</div>
		<div class="col-12 alert alert-danger" v-else>
			Please login to your Soca Islands account if you would like to contact the author of this post.
		</div>
	</div>
</template>
<script>
import { useToast } from 'vue-toastification';
import { 
	collection, 
	addDoc,
	doc, 
	updateDoc, 
	arrayUnion,
} from "firebase/firestore"; 
import { 
	collections, 
	db 
} from "../../firebaseConfig";

const toast = useToast();

export default {
	name: "SendMessageBox",
	props:{
		flagged: {
			type: Boolean,
			default: false,
		},
		author: {
			type: Object,
			required: true,
		},
		item: {
			type: String,
			default: "",
		}
	},
	computed: {
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		messages(){
			return this.$store.state.messages;
		},
		currentConversation(){
			const messages = this.$store.state.messages;
			const authorId = this.author.id;
			const profileId = this.userProfile.id;
			if(Array.isArray(messages) && authorId){
				return messages.filter((msg)=> msg.users.includes(profileId) && msg.users.includes(authorId) && msg.group_chat===false);
			}else{
				return [];
			}
		},
		isFormValid(){
			if(this.message.length < 15){
				return false;
			}
			return true;
		},
	},
	methods:{
		async btnSendMessageClick(){
			this.isBusy = true;
			const {currentConversation} = this;
			const timestamp = Date.now()
			const message = {
				created_at: timestamp,
				updated_at: timestamp,
				group_chat: false,
				messages:[
					{
						content: this.message,
						created_at: timestamp,
						meta: null,
						read: [],
						id: this.userProfile.id,
					}
				],
				read:[this.userProfile.id],
				users:[
					this.userProfile.id, this.author.id
				],
				users_info:[
					{
						name: `${this.userProfile.fname} ${this.userProfile.lname}`,
						id: this.userProfile.id,
					},
					this.author
				],
			};
			try{
				if(currentConversation.length > 0){
					await updateDoc(doc(db, collections.MESSAGES, currentConversation[0].id), {
						messages: arrayUnion(message.messages[0])
					});
				}else{
					await addDoc(collection(db, collections.MESSAGES), message);
				}
				toast.success("Message sent!")
			}catch(error){
				console.log(error);
				toast.error("Message not sent!")
			}finally{
				this.isBusy = false;
			}
		} 
	},
	data(){
		return {
			isBusy: false,
			message: `Hi ${this.author.name}, I am interested in this item - ${ this.item }. Is it still available?`,
		}
	},
	mounted(){
		
	}
}
</script>
