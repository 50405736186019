<template>
	<div class="fab-container" >
		<div class="fab-icon-button bg-dark text-white ">
			<i class="bi" :class="icon"></i>
		</div>
	</div>
</template>
<script>
export default {
	name: "floating-action-button",
	components: {
		
	},
	props: {
		icon: {
			type: String,
			default: 'bi-sliders',
		} 
	},
	computed:{

	},
	data(){
		return{
			isBusy: false,
		}
	},
	methods:{
		
	},
	mounted(){
	}
}
</script>
<style scoped>
.fab-container {
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	cursor: pointer;
	width: 3.5rem;
	height: 3.5rem;
	z-index: 999;
}

.fab-icon-button{
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
}

.fab-icon-button i{
	display:flex;
	align-items:center;
	justify-content:center;
	height: 100%;
	font-size: 1.5rem;
}
</style>

