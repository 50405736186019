<template>
	<div class="row my-2 mb-4 border border-top-0 border-start-0 border-end-0">
		<div class="col-2">
			<button class="btn btn-white" @click="btnLikeClick" :disabled="!isLoggedIn">
				<i class="bi" :class="isLiked ? 'bi-heart-fill' : 'bi-heart'"></i><br/>
				{{ likesCount > 0 ? likesCount : "Likes"  }}
			</button>
		</div>
		<div class="col-2">
			<button class="btn btn-white" disabled>
				<i class="bi bi-share-fill"></i><br/>
				Share
			</button>
		</div>
		<div class="col-2">
			<button class="btn btn-white" @click="btnSaveClick" :disabled="!isLoggedIn">
				<i class="bi"  :class="isSaved ? 'bi-bookmark-fill' : 'bi-bookmark'"></i><br/>
				{{ isSaved ? 'Saved' : 'Save' }}
			</button>
		</div>
		<div class="col-2">
			<button class="btn btn-white" :disabled="!isLoggedIn" @click="showReviewForm = !showReviewForm; showReportForm = false">
				<i class="bi bi-star"></i><br/>
				Review
			</button>
		</div>
		<div class="col-2">
			<button class="btn btn-white" :disabled="!isLoggedIn" @click="showReportForm = !showReportForm; showReviewForm = false">
				<i class="bi bi-flag"></i><br/>
				Report
			</button>
		</div>
		<WriteReviews :author="activeItem.author" @hide-review-form="showReviewForm = !showReviewForm" v-if="showReviewForm" />
		<ReportPost :author="activeItem.author" @hide-report-form="showReportForm = !showReportForm" v-if="showReportForm" />
	</div>
</template>
<script>
import { 
	doc,
	updateDoc,
	arrayRemove,
	arrayUnion, 
} from "firebase/firestore"; 
import { 
	collections, 
	db 
} from "../../firebaseConfig";
import ReportPost from "./ReportPost.vue";
import WriteReviews from "./WriteReviews.vue";
export default {
	name: "item-action-bar",
	props: {
		activeItem: {
			type: Object,
			required: true,
		},
		dbCollection:{
			type: String,
			required: true,
		},
	},
	components: {	
		ReportPost, WriteReviews
	},
	computed:{
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		userProfile(){
			return this.$store.state.userProfile
		},
		isLiked(){
			return this.activeItem.likes.includes(this.userProfile.id);
		},
		isSaved(){
			return this.activeItem.saved_by.includes(this.userProfile.id);
		},
		likesCount(){
			return this.activeItem.likes.length;
		},
	},
	data(){
		return{
			isBusy: false,
			showReviewForm: false,
			showReportForm: false,
		}
	},
	methods:{
		async btnLikeClick(){
			const {activeItem, dbCollection, userProfile} = this;
			if(activeItem.likes.includes(userProfile.id)){
				await updateDoc(doc(db, dbCollection, activeItem.id), {
					likes: arrayRemove(userProfile.id)
				});
			}else{
				await updateDoc(doc(db, dbCollection, activeItem.id), {
					likes: arrayUnion(userProfile.id)
				});
			}
		},
		async btnSaveClick(){
			const {activeItem, userProfile, dbCollection} = this;
			if(activeItem.saved_by.includes(userProfile.id)){
				await updateDoc(doc(db, dbCollection, activeItem.id), {
					saved_by: arrayRemove(userProfile.id)
				});
			}else{
				await updateDoc(doc(db, dbCollection, activeItem.id), {
					saved_by: arrayUnion(userProfile.id)
				});
			}
		},
	},
	mounted(){
	}

}
</script>