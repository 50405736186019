<template>
	<div class="col-12">
		<ul class="list-group my-3" v-if="isLoggedIn">
			<li class="list-group-item">
				<p>Rate:</p>
				<i class="bi h4 m-1 pointer" :class="starRating >= index ? 'bi-star-fill' : 'bi-star'" v-for="index in 5" :key="index" @click="starRatingClick(index)"></i> 
				<br/> &nbsp; 
				<textarea class="form-control my-3" v-model="message" placeholder="Write a review..." rows="5"></textarea>
				<div class="form-check my-3">
					<input class="form-check-input" type="checkbox" :value="true" v-model="dataConsent" id="checkDataConsent">
					<label class="form-check-label" for="checkDataConsent">
						I give consent to Soca Islands to process my data.
					</label>
				</div>
				<div class="mb-3" align="right">
					<button class="btn btn-outline-dark btn-sm me-3" @click="$emit('hide-review-form')">
						<span>Cancel</span>
					</button>
					<button class="btn btn-dark btn-sm" 
					@click="btnSubmitReview"
					:disabled="!isFormValid"
					>
						<span v-if="!isBusy">Submit review</span>
						<div class="spinner-border text-light" role="status" v-else>
							<span class="sr-only">Loading...</span>
						</div>
					</button>
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
import { useToast } from 'vue-toastification';
import { 
	collection, 
	addDoc,
	doc, 
	updateDoc, 
	arrayUnion,
} from "firebase/firestore"; 
import { 
	collections, 
	db 
} from "../../firebaseConfig";

const toast = useToast();

export default {
	name: "WriteReviews",
	props:{
		author: {
			type: Object,
			required: true,
		}
	},
	computed: {
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		userProfile(){
			return this.$store.state.userProfile
		},
		isFormValid(){
			if(this.message.length < 35){
				return false;
			}
			if(!this.dataConsent){
				return false;
			}
			return true;
		},
	},
	methods:{
		async btnSubmitReview(){
			this.isBusy = true;
			const timestamp = Date.now()
			const review = {
				created_at: timestamp,
				updated_at: timestamp,
				details: this.message,
				star_rating: this.starRating,
				author: {
					id: this.userProfile.id,
					name: `${this.userProfile.fname} ${this.userProfile.lname}`,
					created_at: this.userProfile.created_at,
				},
				reported_id: this.author.id
			};
			try{
				await addDoc(collection(db, collections.REVIEWS), review);
				toast.success("Review submitted!");
				this.message = "";
				this.dataConsent = false;
				this.$emit('hide-review-form');
			}catch(error){
				console.log(error);
				toast.error("Review not submitted!")
			}finally{
				this.isBusy = false;
			}
		},
		starRatingClick(stars){
			this.starRating = stars;
		}, 
	},
	data(){
		return {
			isBusy: false,
			message: "",
			starRating: 0,
			dataConsent: false,
		}
	},
	mounted(){
		
	}
}
</script>
