<template>
	<div class="row">
		<div class="col-md-6 col-xl-4 my-4" v-for="index in 6" :key="index">
			<div class="row">
				<div class="col-12 my-2">
					<Skeletor height="200" />
				</div>
				<div class="col-3 my-1">
					<Skeletor height="15" pill/>
				</div>
				<div class="col-12 my-1">
					<Skeletor height="15" pill/>
				</div>
				<div class="col-12 my-1">
					<Skeletor height="15" pill/>
				</div>
				<div class="col-8 my-1">
					<Skeletor height="15" pill/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default ({
	name: "PostSkeleton"
})
</script>
