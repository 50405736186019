<template>
	<div class="row mt-4">
		<div class="col-12">
			<div class="alert alert-info">
				<div class="h3">
					{{title}}
				</div>
				{{body}}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'PageCallOut',
	props: {
		title: {
			type: String,
			required: true,
		},
		body: {
			type: String,
			required: true,
		},
  	},
	mounted(){
		
	}
}
</script>
<style scoped>
.avatar{
	display: inline-block;
    vertical-align: middle;
    position: relative;
	background-color: #000;
    color: #fff;
    border-radius: 50%;
}
.avatar--letter{
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: .9rem;
}
</style>
